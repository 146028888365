import React, { useState, useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { isMobileSize } from '../../../utils'
import useWindowSize from '../../custom-hooks/useWindowSize'
import useMouseOver from '../../custom-hooks/useMouseOver'
import Text from './text'
import { ComponentScroller } from './scroller-base'
import ScrollContext from '../../context/ScrollContext'
import {
    effects,
    useAnimationManager,
} from '../../custom-hooks/useAnimationManager'

import * as imageScrollerStyles from './image-scroller.module.scss'

export default (props) => {
    const size = useWindowSize()
    const [deviceIsMobile, setDevice] = useState(false)

    const containerId = `multi-carousel-images-${props.index}`
    const isMouseOver = useMouseOver(containerId)

    const scrollY = useContext(ScrollContext)

    const animatedElements = {
        imageScroller: {
            id: containerId,
            effect: effects.ON_TOP_REACHED,
        },
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    const vwTOpx = (value) => {
        const result = (size.width * value) / 100
        return result
    }

    let carouselStyle
    let itemStyle
    let scrollStyle
    let sliderStyle
    let desktopVisibilityGutter
    let mobileVisibilityGutter
    let beforeChangeTransform
    let beforeChangeTransformMobile
    let beforeChangeIncrement
    let beforeChangeIncrementMobile

    let itemPerDesktopPage

    carouselStyle = imageScrollerStyles.carouselContainer
    itemStyle = imageScrollerStyles.itemClass
    scrollStyle = imageScrollerStyles.scrollImage
    sliderStyle = imageScrollerStyles.sliderClass

    desktopVisibilityGutter = 0
    mobileVisibilityGutter = null
    beforeChangeTransform = 0
    beforeChangeTransformMobile = 0
    beforeChangeIncrement = vwTOpx(70) - 130
    beforeChangeIncrementMobile = vwTOpx(10)

    itemPerDesktopPage = 1

    if (props.resolution === '3:4 tall') {
        // carouselStyle = imageScrollerStyles.carouselContainer
        // itemStyle = imageScrollerStyles.itemClass
        // scrollStyle = imageScrollerStyles.scrollImage
        // sliderStyle = imageScrollerStyles.sliderClass
    }
    if (props.resolution === '16:9 wide') {
        carouselStyle = imageScrollerStyles.carouselContainerWide
        itemStyle = imageScrollerStyles.itemClassWide
        scrollStyle = imageScrollerStyles.scrollImageWide
        sliderStyle = imageScrollerStyles.sliderClassWide
        itemPerDesktopPage = 1
        beforeChangeTransform = 1
        beforeChangeTransformMobile = -1
        beforeChangeIncrement = vwTOpx(20) - 10
        beforeChangeIncrementMobile = vwTOpx(9)
        desktopVisibilityGutter = 0
        mobileVisibilityGutter = 0
    }
    if (isMobileSize(size.width) !== deviceIsMobile) {
        setDevice(!deviceIsMobile)
    }

    return (
        <div
            className={imageScrollerStyles.collectionContainer}
            style={{
                background: `${props.backgroundColor}`,
                color: `${props.color || 'black'}`,
            }}
            id={containerId}
            key={`imagescroller-${props.index}`}
        >
            <Text
                backgroundColor={props.backgroundColor}
                color={props.color || black}
                showHeadings={props.showHeadings || false}
                heading={props.heading || ''}
                subHeading={props.subHeading || ''}
                showText={props.show || false}
                description={props.description || ''}
                isAnimated
                animationTrigger={animationStates.imageScroller}
            />
            <ComponentScroller
                itemClass={itemStyle}
                carouselContainer={carouselStyle}
                scrollImage={scrollStyle}
                sliderClass={sliderStyle}
                scrollContainer={imageScrollerStyles.listBox}
                centerMode={false}
                isMouseOver={isMouseOver}
                // transform={0}
                gotoLast={props.gotoLast}
                partialVisbile
                itemsOnDesktop={itemPerDesktopPage}
                itemsOnTablet={itemPerDesktopPage}
                itemsOnMobile={1}
                beforeChangeTransform={
                    deviceIsMobile
                        ? beforeChangeTransformMobile
                        : beforeChangeTransform
                }
                beforeChangeIncrement={
                    deviceIsMobile
                        ? beforeChangeIncrementMobile
                        : beforeChangeIncrement
                }
                desktopVisibilityGutter={desktopVisibilityGutter}
                mobileVisibilityGutter={mobileVisibilityGutter}
            >
                {props.image.map((image) => (
                    <GatsbyImage
                        image={
                            image.image.localFile.childImageSharp
                                .gatsbyImageData
                        }
                        key={
                            image.image.localFile.childImageSharp
                                .gatsbyImageData
                        }
                        className={scrollStyle}
                        draggable={false}
                    />
                ))}
            </ComponentScroller>
        </div>
    )
}

import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { injectSanitiseHtml } from '../../../utils'

import * as cardStyles from './card.module.scss'

const Card = (props) => {
    let imageClass = cardStyles.imageSquare
    if (props.resolution === '1:1 square') {
        // default
    }
    if (props.resolution === '16:9 wide') {
        imageClass = cardStyles.imageWide
    }

    return (
        <div className={cardStyles.cardBox} key={`carditem-${props.index}`}>
            <div
                className={
                    props.animationTrigger
                        ? cardStyles.animatedBox
                        : cardStyles.animatedBoxClosed
                }
                style={{ transitionDelay: `${props.index * 0.3 + 0.2}s` }}
            >
                {props.show_image && props.image ? (
                    <GatsbyImage
                        image={
                            props.image.localFile.childImageSharp
                                .gatsbyImageData
                        }
                        key={
                            props.image.localFile.childImageSharp
                                .gatsbyImageData
                        }
                        width={800}
                        className={imageClass}
                        draggable={false}
                    />
                ) : null}
                <div className={cardStyles.textContainer}>
                    <h1>
                        <p>0{props.index + 1}</p>
                        {props.heading}
                    </h1>
                    {injectSanitiseHtml(props.text)}
                </div>
            </div>
        </div>
    )
}

export default Card

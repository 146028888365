import React from 'react'
import _ from 'lodash'
import Quote from '../quote'
import Video from './chapter-types/video'
import Image from './chapter-types/image'
import ImageCollection from './chapter-types/image-collection'
import ImageScroller from './chapter-types/image-scroller'
import ImageWithSideText from './chapter-types/imageWithSideText'
import ImageOverlay from './chapter-types/imageOverlay'
import Slider from './chapter-types/slider'
import Metrics from './chapter-types/metrics'
import Text from './chapter-types/text'
import Services from './chapter-types/services'
import Icons from '../icons'

import * as separatorStyle from './separator.module.scss'

const getBlockJsx = (block, index) => {
    if (block.__typename.match(/.*ContentBlock_Video$/)) {
        return (
            <Video
                index={index}
                backgroundColor={block.backgroundColour}
                url={block.video ? block.video.mediaItemUrl : ''}
                controls={block.controls}
                autoplay={block.autoplay}
                id={Math.random().toString(36).substring(7)}
                key={`video-chapter-${index}`}
            />
        )
    }
    if (block.__typename.match(/.*ContentBlock_Image$/)) {
        return (
            <Image
                index={index}
                blockBackground={block.blockBackgroundColour || 'black'}
                image={block.image || {}}
                videoInstead={block.videoInstead}
                showHeadings={block.showHeadings || false}
                heading={block.heading || ''}
                subHeading={block.subheading || ''}
                metrics={block.metricsFields || []}
                color={block.textColour || 'white'}
                backgroundColor={block.backgroundColour}
                overlayBackgroundColor={block.overlayColor || 'black'}
                overlayOpacity={block.overlayOpacity || 0}
                show={block.showText || false}
                description={block.description || ''}
                logo={block.relatedLogo || {}}
                key={`image-chapter-${index}`}
            />
        )
    }
    if (block.__typename.match(/.*ImageCollection$/)) {
        if (block.textOnTheSide) {
            return (
                <ImageWithSideText
                    index={index}
                    media={block.mixedMedia || {}}
                    showHeadings={block.showHeadings || false}
                    heading={block.heading || ''}
                    subHeading={block.subheading || ''}
                    backgroundColor={block.backgroundColour}
                    color={block.textColour}
                    show={block.showText || false}
                    description={block.description || ''}
                    stickyText={block.stickyText || false}
                    horizontalAlignment={block.sidetextAlignment || 'left'}
                    key={`imageside-chapter-${index}`}
                />
            )
        }
        if (block.imageOverlay) {
            return (
                <ImageOverlay
                    index={index}
                    media={block.mixedMedia || {}}
                    showHeadings={block.showHeadings || false}
                    heading={block.heading || ''}
                    subHeading={block.subheading || ''}
                    backgroundColor={block.backgroundColour}
                    color={block.textColour}
                    show={block.showText || false}
                    description={block.description || ''}
                    key={`image-overlay-${index}`}
                />
            )
        }
        return (
            <ImageCollection
                index={index}
                media={block.mixedMedia || []}
                showHeadings={block.showHeadings || false}
                heading={block.heading || ''}
                subHeading={block.subheading || ''}
                backgroundColor={block.backgroundColour}
                color={block.textColour}
                show={block.showText || false}
                description={block.description || ''}
                stickyText={block.stickyText || false}
                key={`image-collection-${index}`}
            />
        )
    }
    if (block.__typename.match(/.*ImageScroller$/)) {
        return (
            <ImageScroller
                index={index}
                image={block.pics || {}}
                showHeadings={block.showHeadings || false}
                heading={block.heading || ''}
                subHeading={block.subheading || ''}
                backgroundColor={block.backgroundColour}
                color={block.textColour}
                show={block.showText || false}
                description={block.description || ''}
                resolution={block.resolution || '16:9 wide'}
                gotoLast={block.lastFirst || false}
                key={`image-scroller-${index}`}
            />
        )
    }
    if (block.__typename.match(/.*Slider$/)) {
        return (
            <Slider
                index={index}
                showHeadings={block.showHeadings || false}
                heading={block.heading || ''}
                subHeading={block.subheading || ''}
                backgroundColor={block.backgroundColour}
                color={block.textColour}
                show={block.showText || false}
                description={block.description || ''}
                cards={block.cards || []}
                resolution={block.sliderResolution || '16:9 wide'}
                key={`slider-chapter-${index}`}
            />
        )
    }
    if (block.__typename.match(/.*Metric$/)) {
        return (
            <Metrics
                index={index}
                metrics={block.metrics || []}
                backgroundColor={block.backgroundColour}
                useCustomTextColor={block.useCustomTextColour || false}
                textColor={block.textColour}
                key={`metric-chapter-${index}`}
            />
        )
    }
    if (block.__typename.match(/.*Text$/)) {
        return (
            <Text
                index={index}
                backgroundColor={block.backgroundColour}
                color={block.textColour}
                showHeadings={block.showHeadings || false}
                heading={block.heading || ''}
                subHeading={block.subheading || ''}
                showText={block.showText || false}
                description={block.description || ''}
                key={`text-chapter-${index}`}
            />
        )
    }
    if (block.__typename.match(/.*Quote$/)) {
        return (
            <Quote
                index={index}
                quote={block.statement}
                name={block.quotee}
                backgroundColor={block.backgroundColour}
                color={block.textColor}
                key={`quote-chapter-${index}`}
            />
        )
    }
    if (block.__typename.match(/.*Services$/)) {
        return (
            <Services
                index={index}
                title={block.servicesBlockTitle}
                servicePosts={block.servicePosts}
                backgroundColor={block.backgroundColour}
                color={block.textColour}
                key={`services-chapter-${index}`}
            />
        )
    }
    if (block.__typename.match(/.*Logos$/)) {
        return (
            <Icons
                index={index}
                backgroundColor={block.backgroundColour}
                color={block.textColour}
                showHeadings={block.showHeadings || false}
                heading={block.heading || ''}
                subHeading={block.subheading || ''}
                description={block.description || ''}
                show={block.showText || false}
                key={`text-chapter-${index}`}
            />
        )
    }
    if (block.__typename.match(/.*Gallery$/)) {
        return <div key={`gallery-${index}`} />
    }
}

const chaptersToJsx = (chapters, noSeparator) => {
    let contentBlocks = []
    chapters.forEach((chapter) => {
        chapter.contentBlock.forEach((block) => {
            contentBlocks.push(block)
        })
    })

    const preparedBlocks = contentBlocks.map((block, index) =>
        getBlockJsx(block, index)
    )
    if (!noSeparator) {
        preparedBlocks.push(<SeparatorSpace key={'separator'} />)
    }
    return preparedBlocks
}

const Chapters = (props) => {
    return _.isEmpty(props.chapters)
        ? null
        : chaptersToJsx(props.chapters, props.noSeparator)
}

const SeparatorSpace = () => <div className={separatorStyle.separator} />

export default Chapters

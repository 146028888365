// extracted by mini-css-extract-plugin
export const collectionContainer = "slider-module--collection-container--1AxXb";
export const descriptionContainer = "slider-module--description-container--MIlH6";
export const cardBox = "slider-module--card-box--dgCm_";
export const titleBox = "slider-module--title-box--3O8hx";
export const descriptionBox = "slider-module--description-box--2L-Fr";
export const itemClass = "slider-module--item-class--1Dp-K";
export const carouselContainer = "slider-module--carousel-container--2iUCd";
export const listBox = "slider-module--list-box--28z6Y";
export const itemClassWide = "slider-module--item-class-wide--23L-f";
export const carouselContainerWide = "slider-module--carousel-container-wide--3oiW8";
export const scrollImageWide = "slider-module--scroll-image-wide--1SlcM";
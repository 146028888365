import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import * as videoStyles from './video.module.scss'
import useScroll from '../../custom-hooks/useScroll'

export default props => {
    const [isScrolled, setIsScrolled] = useState(false)
    const [videoId, setVideoId] = useState(null)
    const { scrollX, scrollY, scrollDirection } = useScroll()

    useEffect(() => {
        setVideoId(document.getElementById(props.id))
    }, [props.id])

    useEffect(() => {
        const video = videoId
        if (video !== null) {
            video.load()
        }
    }, [])

    const isVideoPivotReached = id => {
        if (isScrolled || videoId === null) {
            return
        }
        const video = videoId
        if (video !== null) {
            let y = video.offsetTop - video.offsetHeight
            if (isMobile) {
                y =
                    video.offsetTop -
                    window.innerHeight +
                    video.offsetHeight / 2
            }
            if (scrollY >= y) {
                setIsScrolled(true)
                video.play().then(
                    () => {},
                    e => {
                        // pass
                    }
                )
            }
        }
    }

    isVideoPivotReached(props.id)
    return (
        <div
            className={videoStyles.videoContainer}
            style={{ background: `${props.backgroundColor}` }}
            key={`videoblock-${props.index}`}
        >
            <video
                id={props.id}
                controls={props.controls}
                autoPlay={props.autoplay}
                playsInline={props.autoplay}
                muted="muted"
            >
                <source
                    src={props.url}
                    type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
            </video>
        </div>
    )
}

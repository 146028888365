import React, { useState, useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { injectSanitiseHtml, isMobileSize } from '../../../utils'
import useWindowSize from '../../custom-hooks/useWindowSize'
import {
    effects,
    useAnimationManager,
} from '../../custom-hooks/useAnimationManager'
import ScrollContext from '../../context/ScrollContext'

import * as imageStyle from './image.module.scss'

export default (props) => {
    const size = useWindowSize()
    const scrollY = useContext(ScrollContext)
    const [deviceIsMobile, setDevice] = useState(false)

    const animatedElements = {
        imageBlockBox: {
            id: `image-block-box-${props.index}`,
            effect: effects.ON_MID_REACHED,
        },
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    if (isMobileSize(size.width) !== deviceIsMobile) {
        setDevice(!deviceIsMobile)
    }

    const getContent = () => {
        if (props.videoInstead) {
            return (
                <div
                    className={imageStyle.videoBox}
                    style={{ background: `${props.backgroundColor}` }}
                >
                    <div
                        className={imageStyle.coloredOverlay}
                        style={{
                            backgroundColor: props.overlayBackgroundColor,
                            opacity: props.overlayOpacity / 100,
                        }}
                    />
                    <div
                        className={imageStyle.backgroundImage}
                        style={{ background: `${props.backgroundColor}` }}
                    >
                        <video
                            // id={props.id}
                            // controls
                            autoPlay
                            playsInline
                            loop
                            muted
                        >
                            <source
                                src={props.videoInstead.mediaItemUrl}
                                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                            />
                        </video>
                    </div>
                    <div
                        className={imageStyle.imageDescription}
                        style={{ background: `${props.backgroundColor}` }}
                    >
                        <h2 style={{ color: `${props.color}` }}>
                            {props.subHeading}
                        </h2>
                        <img src={props.logo.sourceUrl} alt="" />
                    </div>
                </div>
            )
        }
        if (deviceIsMobile) {
            return (
                <div
                    className={imageStyle.imageBox}
                    style={{ background: `${props.backgroundColor}` }}
                >
                    <div
                        className={imageStyle.coloredOverlay}
                        style={{
                            backgroundColor: props.overlayBackgroundColor,
                            opacity: props.overlayOpacity / 100,
                        }}
                    />
                    <GatsbyImage
                        image={
                            props.image.localFile.childImageSharp
                                .gatsbyImageData
                        }
                        key={
                            props.image.localFile.childImageSharp
                                .gatsbyImageData
                        }
                        width={600}
                        className={imageStyle.backgroundImage}
                    />
                    <div
                        className={imageStyle.imageDescription}
                        style={{ background: `${props.backgroundColor}` }}
                    >
                        <h2 style={{ color: `${props.color}` }}>
                            {props.subHeading}
                        </h2>
                        <img src={props.logo.sourceUrl} alt="" />
                    </div>
                </div>
            )
        }
        return (
            <div
                className={imageStyle.imageBox}
                style={{ background: `${props.backgroundColor}` }}
            >
                <div
                    className={imageStyle.coloredOverlay}
                    style={{
                        backgroundColor: props.overlayBackgroundColor,
                        opacity: props.overlayOpacity / 100,
                    }}
                />
                <GatsbyImage
                    image={
                        props.image.localFile.childImageSharp
                            .gatsbyImageData
                    }
                    key={
                        props.image.localFile.childImageSharp
                            .gatsbyImageData
                    }
                    width={610}
                    height={438}
                    className={imageStyle.backgroundImage}
                />
                <div
                    className={imageStyle.imageDescription}
                    style={{ background: `${props.backgroundColor}` }}
                >
                    <h2 style={{ color: `${props.color}` }}>
                        {props.subHeading}
                    </h2>
                    <img src={props.logo.sourceUrl} alt="" />
                </div>
            </div>
        )
    }
    return (
        <div
            style={{ background: props.blockBackground, color: 'white' }}
            className={imageStyle.serviceContainer}
            key={`imageblocks-${props.index}`}
        >
            <div
                className={
                    animationStates.imageBlockBox
                        ? imageStyle.animationBox
                        : imageStyle.animationBoxClosed
                }
                id={animatedElements.imageBlockBox.id}
            >
                <div className={imageStyle.title}>
                    <h1 style={{ color: `${props.color}` }}>{props.heading}</h1>
                </div>
                {getContent()}
                <div className={imageStyle.metricsBox}>
                    <div className={imageStyle.titleMobile}>
                        <h1 style={{ color: `${props.color}` }}>
                            {props.heading}
                        </h1>
                    </div>
                    <div className={imageStyle.metrics}>
                        {props.metrics.map((metric) => (
                            <h3
                                key={metric.metricDescription}
                                style={{ color: `${props.color}` }}
                            >
                                {metric.metricDescription}
                            </h3>
                        ))}
                    </div>
                    {props.show
                        ? injectSanitiseHtml(props.description, 'div', {
                              margin: 0,
                              borderTop: '1px solid white',
                              color: `${props.color}`,
                          })
                        : null}
                </div>
            </div>
        </div>
    )
}

import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Text from './text'
import { isMobileSize } from '../../../utils'
import useWindowSize from '../../custom-hooks/useWindowSize'

import * as imageOverlayStyles from './imageOverlay.module.scss'

export default (props) => {
    const size = useWindowSize()
    const [deviceIsMobile, setDevice] = useState(false)

    if (isMobileSize(size.width) !== deviceIsMobile) {
        setDevice(!deviceIsMobile)
    }

    const isAnyTextAvailable = () => {
        if (!props.showHeadings && !props.show) {
            return false
        }
        return true
    }

    const getTextElement = () => {
        return isAnyTextAvailable() ? (
            <Text
                backgroundColor={props.backgroundColor}
                color={props.color || black}
                showHeadings={props.showHeadings || false}
                heading={props.heading || ''}
                subHeading={props.subHeading || ''}
                showText={props.show || false}
                description={props.description || ''}
                padding={false}
            />
        ) : null
    }

    const containerStyle = {
        position: 'absolute',
    }

    const containerStyleMobile = {
        position: 'relative',
    }

    return (
        <div
            className={imageOverlayStyles.collectionContainer}
            style={{
                background: `${props.backgroundColor}`,
                color: `${props.color || 'black'}`,
            }}
        >
            {getTextElement()}
            <div
                className={imageOverlayStyles.imageBox}
                key={`imageoverlay-${props.index}`}
            >
                {props.media.map((media) => {
                    if (media.__typename.match(/.*MixedMedia_Image$/)) {
                        return (
                            <GatsbyImage
                                image={
                                    media.image.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                                key={
                                    media.image.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                                width={1600}
                                className={imageOverlayStyles.galleryItem}
                                style={
                                    deviceIsMobile
                                        ? containerStyleMobile
                                        : containerStyle
                                }
                            />
                        )
                    }
                    if (media.__typename.match(/.*MixedMedia_Video$/)) {
                        return (
                            <video
                                controls={!media.noControls}
                                autoPlay={media.autoplay}
                                playsInline={media.autoplay}
                                loop={media.loop}
                                muted="muted"
                                className={imageOverlayStyles.galleryItem}
                            >
                                <source
                                    src={media.video.sourceUrl}
                                    type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                                />
                            </video>
                        )
                    }
                })}
            </div>
        </div>
    )
}

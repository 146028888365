import React, { useContext } from 'react'
import Text from './project-components/chapter-types/text'
import clientData from './custom-hooks/useClientData'
import ScrollContext from './context/ScrollContext'
import {
    effects,
    useAnimationManager,
} from './custom-hooks/useAnimationManager'
import * as iconStyles from './icons.module.scss'

const Logo = ({ color, client }) => (
    // <div className={iconStyles.icon} key={logo.id}>
    //     <svg>
    //         <use xlinkHref={`#${logo.id}`} fill={color} />
    //     </svg>
    // </div>
    <div className={iconStyles.icon} key={client.node.title}>
        <img
            src={client.node.client_page.clientLogo.sourceUrl}
            alt={client.node.title}
        />
    </div>
)

const orderIcons = icons => {
    const orderedIcons = []

    const order = icons.map(icon => icon.node.client_page.order)
    order.sort((a, b) => a - b)
    let iconsYetToSort = [...icons]
    order.forEach(orderNumber => {
        let didFind = false
        let findIndex = null
        iconsYetToSort.forEach((icon, index) => {
            if (orderNumber === icon.node.client_page.order && !didFind) {
                orderedIcons.push(icon)
                didFind = true
                findIndex = index
            }
        })
        if (didFind && findIndex) {
            iconsYetToSort.splice(findIndex, 1)
            didFind = false
            findIndex = null
        }
    })
    return orderedIcons
}

const Icons = props => {
    const payload = clientData()

    const scrollY = useContext(ScrollContext)

    const animatedElements = {
        logoIcons: {
            id: `logoIconsContainerId-${props.index}`,
            effect: effects.ON_LOW_REACHED,
        },
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    const clients = payload.allWpClient.edges
    const orderedClients = orderIcons(clients)
    const isAnyTextAvailable = () => {
        if (!props.showHeadings && !props.show) {
            return false
        }
        return true
    }

    return (
        <div
            className={iconStyles.iconContainer}
            style={{ backgroundColor: props.backgroundColor }}
        >
            {isAnyTextAvailable() ? (
                <div
                    className={iconStyles.descriptionContainer}
                    id={animatedElements.logoIcons.id}
                >
                    <Text
                        backgroundColor={props.backgroundColor}
                        color={props.color || 'black'}
                        showHeadings={props.showHeadings || false}
                        heading={props.heading || ''}
                        subHeading={props.subHeading || ''}
                        showText={props.show || false}
                        description={props.description || ''}
                        padding={false}
                        isAnimated
                        animationTrigger={animationStates.logoIcons}
                    />
                </div>
            ) : null}
            {orderedClients.map((client, index) => (
                <Logo
                    color={props.color}
                    client={client}
                    key={`logobox-${client.node.title}-${index}`}
                />
            ))}
        </div>
    )
}

Icons.defaultProps = {
    color: '#262626',
    backgroundColor: 'white',
}

export default Icons

// extracted by mini-css-extract-plugin
export const collectionContainer = "image-scroller-module--collection-container--27MDF";
export const descriptionContainer = "image-scroller-module--description-container--wxvm7";
export const titleBox = "image-scroller-module--title-box--3f9wI";
export const descriptionBox = "image-scroller-module--description-box--2vYQv";
export const itemClass = "image-scroller-module--item-class--3YSE1";
export const carouselContainer = "image-scroller-module--carousel-container--1SPIC";
export const listBox = "image-scroller-module--list-box--1NY33";
export const scrollImage = "image-scroller-module--scroll-image--xY_o6";
export const itemClassWide = "image-scroller-module--item-class-wide--2GU3B";
export const carouselContainerWide = "image-scroller-module--carousel-container-wide--jOZO_";
export const scrollImageWide = "image-scroller-module--scroll-image-wide--DO3mX";
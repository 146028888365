import React, { useState, useContext } from 'react'
import { isTabletSize, isMobileSize } from '../../../utils'
import { ComponentScroller } from './scroller-base'
import useWindowSize from '../../custom-hooks/useWindowSize'
import useMouseOver from '../../custom-hooks/useMouseOver'
import ScrollContext from '../../context/ScrollContext'
import {
    effects,
    useAnimationManager,
} from '../../custom-hooks/useAnimationManager'
import Card from './card'
import Text from './text'

import * as sliderStyles from './slider.module.scss'

export default props => {
    let carouselStyle
    let itemStyle
    let sliderStyle
    let desktopVisibilityGutter
    let mobileVisibilityGutter
    let beforeChangeTransform
    let beforeChangeTransformMobile
    let beforeChangeIncrement
    let beforeChangeIncrementMobile
    let beforeChangeIncrementTablet

    const size = useWindowSize()
    const scrollY = useContext(ScrollContext)
    const [deviceIsMobile, setDevice] = useState(true)
    const [deviceIsTablet, setTabDevice] = useState(true)

    const containerId = `multi-carousel-slider-${props.index}`
    const isMouseOver = useMouseOver(containerId)

    const animatedElements = {
        slider: {
            id: containerId,
            effect: effects.ON_MID_REACHED,
        },
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    const vwTOpx = value => {
        const result = (size.width * value) / 100
        return result
    }

    carouselStyle = sliderStyles.carouselContainer
    itemStyle = sliderStyles.itemClass
    sliderStyle = sliderStyles.sliderClass
    desktopVisibilityGutter = 0
    mobileVisibilityGutter = 0
    beforeChangeTransform = 0
    beforeChangeTransformMobile = 0
    beforeChangeIncrement = vwTOpx(65)
    beforeChangeIncrementMobile = vwTOpx(10) - 20
    beforeChangeIncrementTablet = vwTOpx(30) - 25

    if (props.resolution === '1:1 square') {
        // default
    }
    if (props.resolution === '16:9 wide') {
        carouselStyle = sliderStyles.carouselContainerWide
        itemStyle = sliderStyles.itemClassWide
        sliderStyle = sliderStyles.sliderClassWide
        beforeChangeTransform = 0
        beforeChangeTransformMobile = 0
        desktopVisibilityGutter = 0
        mobileVisibilityGutter = 0
        beforeChangeIncrement = vwTOpx(30)
        beforeChangeIncrementMobile = vwTOpx(10) + 15
        beforeChangeIncrementTablet = vwTOpx(50) + 15
    }

    if (isMobileSize(size.width) !== deviceIsMobile) {
        setDevice(!deviceIsMobile)
    }

    if (isTabletSize(size.width) !== deviceIsTablet) {
        setTabDevice(!deviceIsTablet)
    }

    const renderCarouselOrNo = (cards, triggerAnimation) => {
        let incrementer = beforeChangeIncrement
        if (deviceIsTablet) {
            incrementer = beforeChangeIncrementTablet
        }
        if (deviceIsMobile) {
            incrementer = beforeChangeIncrementMobile
        }
        if (cards.length > 3 || deviceIsTablet || deviceIsMobile) {
            return (
                <ComponentScroller
                    itemClass={itemStyle}
                    carouselContainer={carouselStyle}
                    sliderClass={sliderStyle}
                    scrollContainer={sliderStyles.listBox}
                    centerMode={false}
                    transform={0}
                    beforeChangeTransform={
                        deviceIsMobile
                            ? beforeChangeTransformMobile
                            : beforeChangeTransform
                    }
                    beforeChangeIncrement={incrementer}
                    partialVisbile
                    itemsOnDesktop={1}
                    desktopVisibilityGutter={desktopVisibilityGutter}
                    itemsOnTablet={1}
                    itemsOnMobile={1}
                    mobileVisibilityGutter={mobileVisibilityGutter}
                    isMouseOver={isMouseOver}
                >
                    {props.cards.map((card, index) => (
                        <Card
                            background_colour={card.card_colour}
                            heading={card.heading}
                            show_image={card.showImage}
                            image={card.image}
                            text={card.text}
                            index={index}
                            resolution={props.resolution}
                            animationTrigger={triggerAnimation}
                            key={`card-comp-${index}`}
                        />
                    ))}
                </ComponentScroller>
            )
        }
        return (
            <div className={sliderStyles.cardBox}>
                {props.cards.map((card, index) => (
                    <Card
                        background_colour={card.card_colour}
                        heading={card.heading}
                        show_image={card.showImage}
                        image={card.image}
                        text={card.text}
                        index={index}
                        resolution={props.resolution}
                        animationTrigger={triggerAnimation}
                        key={`card-comp-${index}`}
                    />
                ))}
            </div>
        )
    }

    return (
        <div
            className={sliderStyles.collectionContainer}
            style={{
                background: `${props.backgroundColor}`,
                color: `${props.color || 'black'}`,
            }}
            key={`slider-${props.index}`}
        >
            <Text
                backgroundColor={props.backgroundColor}
                color={props.color || black}
                showHeadings={props.showHeadings || false}
                heading={props.heading || ''}
                subHeading={props.subHeading || ''}
                showText={props.show || false}
                description={props.description || ''}
                padding={true}
                isAnimated
                animationTrigger={animationStates.slider}
            />
            <div id={containerId}>
                {props.cards
                    ? renderCarouselOrNo(props.cards, animationStates.slider)
                    : null}
            </div>
        </div>
    )
}

import React, { useContext } from 'react'
import ScrollContext from '../../context/ScrollContext'
import {
    effects,
    useAnimationManager,
} from '../../custom-hooks/useAnimationManager'
import * as servicesStyles from './services.module.scss'

const PortfolioPage = props => {
    const scrollY = useContext(ScrollContext)

    const animatedElements = {
        servicesAnimation: {
            id: 'services-box-id',
            effect: effects.ON_MID_REACHED,
        },
    }
    if (props.servicePosts && props.servicePosts.length !== 0) {
        props.servicePosts.forEach((element, index) => {
            animatedElements[`servicesAnimationNo${index}`] = {
                id: `services-box-id-${index}`,
                effect: effects.ON_LOW_REACHED,
            }
        })
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    return (
        <div
            style={{ background: props.backgroundColor, color: props.color }}
            className={servicesStyles.blackContainer}
            key={`${props.title}`}
        >
            <div className={servicesStyles.paddedServices}>
                <h2
                    className={
                        animationStates.servicesAnimation
                            ? servicesStyles.titleOpen
                            : servicesStyles.titleClosed
                    }
                    style={{ color: props.color }}
                >
                    {props.title}
                </h2>
                <div
                    className={servicesStyles.aspects}
                    id={animatedElements.servicesAnimation.id}
                >
                    {props.servicePosts.map((post, index) => (
                        <div
                            className={
                                animationStates[`servicesAnimationNo${index}`]
                                    ? servicesStyles.aspectBox
                                    : servicesStyles.aspectBoxClosed
                            }
                            style={{
                                transitionDelay: `${index * 0.1}s`,
                            }}
                            id={`services-box-id-${index}`}
                            key={post.serviceName}
                        >
                            <div className={servicesStyles.counter}>
                                <p style={{ color: props.color }}>
                                    0{index + 1}
                                </p>
                            </div>
                            <div className={servicesStyles.aspect}>
                                <h3 style={{ color: props.color }}>
                                    {post.serviceName}
                                </h3>
                                <p style={{ color: props.color }}>
                                    {post.serviceDescription}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PortfolioPage

PortfolioPage.defaultProps = {
    color: 'white',
    backgroundColor: 'black',
    title: 'Title',
}

// extracted by mini-css-extract-plugin
export const serviceContainer = "image-module--service-container--1jO9R";
export const animationBox = "image-module--animation-box--2Fcdk";
export const animationBoxClosed = "image-module--animation-box-closed--1O9Xp";
export const title = "image-module--title--e9Oe5";
export const titleMobile = "image-module--title-mobile--zjev1";
export const imageBox = "image-module--image-box--172Yz";
export const videoBox = "image-module--video-box--IhENP";
export const backgroundImage = "image-module--background-image--2JZHz";
export const imageDescription = "image-module--image-description--3-dGr";
export const coloredOverlay = "image-module--colored-overlay--34pW3";
export const metricsBox = "image-module--metrics-box--2zV_n";
export const metrics = "image-module--metrics--2l66y";
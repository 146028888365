import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const payload = useStaticQuery(
        graphql`
            query {
                allWpClient {
                    edges {
                        node {
                            title
                            client_page {
                                showInLogoGrid
                                order
                                clientLogo {
                                    sourceUrl
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return payload
}

import React, { useContext } from 'react'
import { injectSanitiseHtml } from '../../../utils'
import {
    effects,
    useAnimationManager,
} from '../../custom-hooks/useAnimationManager'
import ScrollContext from '../../context/ScrollContext'
import * as metricStyles from './metrics.module.scss'

const getMetrics = metric => {
    if (metric.__typename.match(/.*DescriptionMetric$/)) {
        return (
            <div
                className={metricStyles.metricsDescription}
                key={`descmetric-${metric.title}`}
            >
                <div>
                    <p className={metricStyles.metricTitleRight}>
                        {metric.title}
                    </p>
                    <p className={metricStyles.count}>{metric.count}</p>
                </div>
                {injectSanitiseHtml(metric.description)}
            </div>
        )
    }
    if (metric.__typename.match(/.*FactMetric$/)) {
        return (
            <div
                className={metricStyles.metricsBox}
                key={`factmetric-${metric.title}`}
            >
                <p className={metricStyles.metricTitle}>{metric.title}</p>
                <p className={metricStyles.fact}>
                    {metric.scale} <span>{metric.unit}</span>
                </p>
                <p className={metricStyles.factDescribe}>
                    {metric.factDescription}
                </p>
            </div>
        )
    }
    if (metric.__typename.match(/.*NumberMetric$/)) {
        return (
            <div
                className={metricStyles.metricsBox}
                key={`nummetric-${metric.title}`}
            >
                <p className={metricStyles.metricTitle}>{metric.title}</p>
                <h4 className={metricStyles.count}>{metric.integer}</h4>
            </div>
        )
    }
}

export default props => {
    const scrollY = useContext(ScrollContext)

    const animatedElements = {
        metricElement: {
            id: `metrics-component-${props.index}`,
            effect: effects.ON_LOW_REACHED,
        },
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    const metricList = props.metrics.map((metric, index) =>
        getMetrics(metric, animationStates.metricElement)
    )
    const styles = {
        background: props.backgroundColor || 'transparent',
        color: props.useCustomTextColor ? props.textColor : 'black',
    }
    return (
        <div
            style={styles}
            className={
                animationStates.metricElement
                    ? metricStyles.metricsContainer
                    : metricStyles.metricsContainerClosed
            }
            key={`metrics-${props.index}`}
            id={animatedElements.metricElement.id}
        >
            {metricList}
        </div>
    )
}

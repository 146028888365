import React, { useContext } from 'react'
import ScrollContext from './context/ScrollContext'
import {
    effects,
    useAnimationManager,
} from './custom-hooks/useAnimationManager'
import { injectSanitiseHtml } from '../utils'

import * as quouteStyles from './quote.module.scss'

const Quote = props => {
    const scrollY = useContext(ScrollContext)

    const animatedElements = {
        animateQuote: {
            id: `quote-block-${props.index}`,
            effect: effects.ON_MID_REACHED,
        },
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    return (
        <div
            className={quouteStyles.quoteContainer}
            style={{
                backgroundColor: props.backgroundColor,
            }}
            key={props.name}
        >
            <div
                className={
                    animationStates.animateQuote
                        ? quouteStyles.quoteAnimationBox
                        : quouteStyles.quoteAnimationBoxClosed
                }
                id={animatedElements.animateQuote.id}
            >
                {injectSanitiseHtml(props.quote, 'div', {
                    color: props.color,
                })}
                <p
                    style={{
                        color: props.color,
                    }}
                    className={quouteStyles.quotee}
                >
                    {props.name}
                </p>
            </div>
        </div>
    )
}

Quote.defaultProps = {
    color: '#262626',
    backgroundColor: '#ffffff',
    name: 'WR&Co',
}

export default Quote

import React, { useState, useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Text from './text'
import ScrollContext from '../../context/ScrollContext'
import {
    effects,
    useAnimationManager,
} from '../../custom-hooks/useAnimationManager'

import * as imageCollectionStyles from './image-collection.module.scss'

export default (props) => {
    const scrollY = useContext(ScrollContext)

    const animatedElements = {
        imageCollection: {
            id: `imageCollectionContainerId-${props.index}`,
            effect: effects.ON_TOP_REACHED,
        },
    }

    const animationStates = useAnimationManager(animatedElements, scrollY)

    const getVideoOrImageHtml = (media) => {
        if (
            media.__typename.match(/.*MixedMedia_Image$/)
        ) {
            return (
                <GatsbyImage
                    image={
                        media.image.localFile.childImageSharp.gatsbyImageData
                    }
                    key={media.image.localFile.childImageSharp.gatsbyImageData}
                    width={1600}
                />
            )
        }

        if (
            media.__typename.match(/.*MixedMedia_Video$/)
        ) {
            return (
                <video
                    controls={!media.noControls}
                    autoPlay={media.autoplay}
                    playsInline={media.autoplay}
                    loop={media.loop}
                    muted="muted"
                >
                    <source
                        src={media.video.mediaItemUrl}
                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                    />
                </video>
            )
        }
    }

    const parallelFlexboxes = (media) => {
        const copy = [...media]
        if (copy.length === 1) {
            const single = copy.shift()
            if (
                single.__typename.match(/.*MixedMedia_Image$/)
            ) {
                return (
                    <div className={imageCollectionStyles.gallery}>
                        <GatsbyImage
                            image={
                                single.image.localFile.childImageSharp
                                    .gatsbyImageData
                            }
                            key={
                                single.image.localFile.childImageSharp
                                    .gatsbyImageData
                            }
                            imgStyle={{ width: '100%', objectFit: 'contain' }}
                        />
                    </div>
                )
            }
            if (
                single.__typename.match(/.*MixedMedia_Video$/)
            ) {
                return (
                    <div className={imageCollectionStyles.gallery}>
                        <video
                            controls={!single.noControls}
                            autoPlay={single.autoplay}
                            playsInline={single.autoplay}
                            loop={single.loop}
                            muted="muted"
                            style={{ width: '100%' }}
                        >
                            <source
                                src={single.video.mediaItemUrl}
                                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                            />
                        </video>
                    </div>
                )
            }
        }
        if (copy.length === 2) {
            const first = copy.shift()
            const second = copy.shift()

            return (
                <div className={imageCollectionStyles.gallery}>
                    {getVideoOrImageHtml(first)}
                    {getVideoOrImageHtml(second)}
                </div>
            )
        }
        const firstListItem = copy.shift()
        const firstItem = getVideoOrImageHtml(firstListItem)

        const leftFlexContainer = []
        const rightFlexContainer = []

        copy.forEach((item, index) => {
            // console.log(item)
            if (index % 2 === 0) {
                leftFlexContainer.push(getVideoOrImageHtml(item))
            } else {
                rightFlexContainer.push(getVideoOrImageHtml(item))
            }
        })

        return (
            <div className={imageCollectionStyles.gallery}>
                <div>{firstItem}</div>
                <div className={imageCollectionStyles.leftBox}>
                    {rightFlexContainer}
                </div>
                <div className={imageCollectionStyles.rightBox}>
                    {leftFlexContainer}
                </div>
            </div>
        )
    }

    const isAnyTextAvailable = () => {
        if (!props.showHeadings && !props.show) {
            return false
        }
        return true
    }
    return (
        <div
            className={imageCollectionStyles.collectionContainer}
            style={{
                background: `${props.backgroundColor}`,
                color: `${props.color || 'black'}`,
            }}
            key={`imagecollection-${props.index}`}
            id={animatedElements.imageCollection.id}
        >
            {isAnyTextAvailable() ? (
                <div className={imageCollectionStyles.descriptionContainer}>
                    <Text
                        backgroundColor={props.backgroundColor}
                        color={props.color || 'black'}
                        showHeadings={props.showHeadings || false}
                        heading={props.heading || ''}
                        subHeading={props.subHeading || ''}
                        showText={props.show || false}
                        description={props.description || ''}
                        padding={false}
                        isAnimated
                        animationTrigger={animationStates.imageCollection}
                    />
                </div>
            ) : null}
            {props.media &&
                props.media.length !== 0 &&
                parallelFlexboxes(props.media)}
        </div>
    )
}
